import React from "react";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";

export default function NavBar() {
    return (
        <header className="bg-black">
            <div className="container mx-auto flex flex-wrap justify-between sm:grid-cols-1">
                <nav className="flex flex-wrap">
                    <NavLink 
                        to="/"
                        exact 
                        activeClassName="text-blue-300"
                        className="inflex-flex items-center py-6 px-3 mr-4 text-blue-300 hover:text-blue-400 text-4xl font-bold cursive tracking-widest sm:col-span-6"
                    >
                        Vinícius
                    </NavLink>
                    <NavLink 
                        to="/certificados"
                        activeClassName="text-blue-200 b-yellow"
                        className="inline-flex items-center py-3 px-3 my-6 rounded text-yellow-300 hover:text-blue-200"
                    >
                        Certificados
                    </NavLink>
                    <NavLink 
                        to="/project"
                        activeClassName="text-blue-200"
                        className="inline-flex items-center py-3 px-3 my-6 rounded text-yellow-300 hover:text-blue-200"
                        >
                        Portfolio
                    </NavLink>
                    <NavLink 
                        to="/about"
                        activeClassName="text-blue-200"
                        className="inline-flex items-center py-3 px-3 my-6 rounded text-yellow-300 hover:text-blue-200"
                        >
                        Sobre mim
                    </NavLink>
                </nav>
                <div className="inline-flex py-3 px-3 my-6">
                    <SocialIcon 
                    url="https://www.linkedin.com/in/vinícius-silva-76b2991b4" 
                    className="mr-4" 
                    target="_blank" 
                    fgColor="#fff" 
                    style={{ height: 35, width: 35 }} 
                    />
                    <SocialIcon 
                    url="https://github.com/devnicius/" 
                    className="mr-4" 
                    target="_blank" 
                    fgColor="#fff" 
                    style={{ height: 35, width: 35 }} 
                    />
                    <SocialIcon 
                    url="https://api.whatsapp.com/send?phone=5516996438921&text=Ol%C3%A1%2C%20Vin%C3%ADcius" 
                    className="mr-4" 
                    target="_blank" 
                    fgColor="#fff" 
                    style={{ height: 35, width: 35 }} 
                    />
                </div>
            </div>
        </header>
    )
};