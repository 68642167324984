import React from "react";
import image from "../background-2.jpg";

/*document.addEventListener('DOMContentLoaded', function() {
    if (window.innerHeight > window.innerWidth) {
        alert("Para uma melhor experiência, gire a tela do smartphone no modo paisagem.");
    }
}, false);*/

export default function Home() {

    return (
        <main style={{background: '#fedd20'}}> 
            <img src={image} alt="Batman" className="absolute w-full h-screen object-cover inline-block bg-fixed bg-center" />
            <section className="flex justify-start pt-12 pb-12 md:pl-0 sm:pl-6 lg:pt-64 lg:px-8">
                <h1 className="absolute text-3xl text-dark font-bold cursive leading-none lg:leading-snug home-name">Olá. Eu sou o Vinícius!</h1>
            </section>
        </main>
    )
};
