import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";

export default function Post() {
    const [postData, setPost] = useState(null);
    useEffect(() => {
        sanityClient.fetch(`*[_type == "post"] | order(publicadoEm asc)
        {
                title,
                slug,
                postType,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                    alt
                },
            }`)
            .then((data) => setPost(data.reverse()))
            .catch(console.error)
    }, []); 

    const filterList = ["Todos", "Back-end", "Devops", "Soft-skills", "Outros"];

    const filter = (e) => {
      var value = e.target.value;
      if (value === "Todos" || value === undefined) { value = ""; }
      showDiv(value)
    };

    function showDiv(value) {
      const x = document.getElementsByClassName("filter");
      for (var i = 0; i < x.length; i++) {
        w3RemoveClass(x[i], "show");
        if (x[i].className.indexOf(value) > -1) w3AddClass(x[i], "show");
      }
    }

    function w3AddClass(element, name) {
      var i, arr1, arr2;
      arr1 = element.className.split(" ");
      arr2 = name.split(" ");
      for (i = 0; i < arr2.length; i++) {
        if (arr1.indexOf(arr2[i]) === -1) {element.className += " " + arr2[i];}
      }
    };
    
    function w3RemoveClass(element, name) {
      var i, arr1, arr2;
      arr1 = element.className.split(" ");
      arr2 = name.split(" ");
      for (i = 0; i < arr2.length; i++) {
        while (arr1.indexOf(arr2[i]) > -1) {
          arr1.splice(arr1.indexOf(arr2[i]), 1);     
        }
      }
      element.className = arr1.join(" ");
    };
    
    return (
        <main className="bg-yellow-200 min-h-screen p-12" onLoad={filter}>
            <section className="container mx-auto">
                <h1 className="text-5xl flex text-black justify-center cursive">Meus certificados</h1>
                <h2 className="text-lg text-blue-300 font-bold flex justify-center mb-10">Bem-vindo(a) à minha página de certificados.</h2>
                <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-8 m-6 bg-yellow-100 p-4 shadow-xl opacity-75">
                { filterList.map((value, index) => ( 
                <button
                  key={index}
                  type="button"
                  value={value}
                  onClick={filter}
                  className="bg-transparent hover:bg-yellow-200 focus:bg-yellow-200 text-black font-semibold py-2 px-4 rounded-lg"
                  placeholder="Filter"
                  id="content_filter"
                >
                  {value}
                </button>
              ))}
                </div>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {postData && postData.map((post, index) => (
                    <article 
                    className={"filter " + post.postType}
                    key={index}
                     >
                        <Link to={"/certificados/" + post.slug.current} key={post.slug.current}>
                            <span className="block h-64 relative rounded shadow leading-snug bg-white border-l-8 border-yellow-400 hover:opacity-75" key={index}>
                                <img 
                                  src={post.mainImage.asset.url}
                                  alt={post.mainImage.alt}
                                  className="w-full h-full rounded-r object-cover absolute"
                                />
                                <span className="block relative h-full w-full flex justify-start items-end pr-4 pb-4">
                                  <h3 className="text-black text-lg font-bold px-3 py-4 bg-blue-400 rounded-r">
                                    {post.title}
                                  </h3>
                                </span>
                            </span>
                        </Link>
                    </article>
                  ))}
                </div>
            </section>
        </main>
    )
};