import React, { useEffect, useState } from "react";
import sanityClient from '../client.js';
//import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';

//const builder = imageUrlBuilder(sanityClient);
/*function urlFor(source) {
    return builder.image(source);
}*/

export default function About() {
    const [author, setAuthor] = useState(null);

    useEffect(() => {
        sanityClient.fetch(`*[_type == "author"] {
            name,
            bio,
            "authorImage": image.asset->url
        }`).then((data) => setAuthor(data[0]))
            .catch(console.error);
    }, []);

    if(!author) return <div>Loading...</div>

    return (
        <main className="relative bg-yellow-200 h-min-screen">
            <div className="p-5 sm:p-5 lg:pt-24 container mx-auto relative">
                <section className="bg-yellow-100 rounded-lg shadow-2xl lg:flex p-12 sm:p-6 mx-auto">
                 {/* <img src={urlFor(author.authorImage).url()} className="rounded w-full h-full lg:w-64 lg:w-64 mr-8 ml-2 mt-6 flex" alt={author.name} /> */}
                    <div className="text-lg flex flex-col justify-center mx-8">
                        <h1 className="cursive text-6xl text-blue-200">
                            Hey, eu sou o{" "}
                            <span className="text-blue-300">{author.name}</span>
                        </h1>
                        <div className="prose text-justify mx-6">
                            <BlockContent blocks={author.bio} projectId="b6k3l5k3" dataset="production" />
                        </div>
                    </div>
                </section>

            </div>
        </main>
    )
};
