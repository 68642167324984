import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import sanityClient from '../client.js';
import BlockContent from '@sanity/block-content-to-react';
import ModalImage from 'react-modal-image';


export default function SinglePost() {
    const [singlePost, setSinglePost] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient.fetch(`*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            body,
        }`).then((data) => setSinglePost(data[0]))
            .catch(console.error);
    }, [slug]);

    if (!singlePost) return <div>Loading...</div>;

    return (
        <main className="min-h-screen p-12 w-auto">
            <article className="container md:flex md:justify-center p-1 shadow-lg mx-auto rounded-lg bg-yellow-100">
                <header className="relative p-0 my-auto">
                    <ModalImage 
                    small={singlePost.mainImage.asset.url}
                    large={singlePost.mainImage.asset.url}
                    hideDownload={true}
                    alt={singlePost.title} 
                    className="Modal object-cover mx-auto rounded-t hover:opacity-75"
                    />
                </header>
                <div className="lg:pl-6 sm:p-12 py-1 my-auto prose lg:prose-xl w-full h-full bg-yellow-100 bg-opacity-75">
                <div className="bg-yellow-200 bg-opacity-50 ">
                    <h1 className="cursive text-3xl lg:text-6xl mb-4 text-white">{singlePost.title}</h1>
                </div>
                    <BlockContent className="text-justify" blocks={singlePost.body} projectId="b6k3l5k3" dataset="production"/>
                </div>
            </article>
        </main>
    )
};