import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";

export default function Project() {
    const [projectData, setProjectData] = useState(null)

    useEffect(() => {
        sanityClient.fetch(`*[_type == "project"]{
            title,
            date,
            place,
            description,
            projectType,
            link,
            tags
        }`).then((data) => setProjectData(data))
        .catch(console.error);

    }, []);
   return (
       <main className="bg-yellow-200 min-h-screen p-12">
           <section className="container mx-auto">
               <h1 className="text-5xl text-black flex justify-center cursive"> Meus projetos</h1>
               <h2 className="text-lg text-blue-300 font-bold flex justify-center">Bem-vindo(a) à minha página de projetos.</h2>
               <section className="grid grid-cols-2 gap-8">
                   {projectData && projectData.map((project, index) => (
                   <article className="relative rounded-lg border border-yellow-300 p-8 mt-12 bg-yellow-100" key={index}>
                       <h3 className="text-blue-200 text-3xl font-bold mb-2 hover:opacity-75">
                            <a href={project.link} alt={project.title} target="_blank" rel="noopener noreferrer" className="text-blue-300">
                               {project.title}
                            </a>
                       </h3>
                       <div className="text-black text-base space-x-4">
                           <span>
                               <strong className="font-bold"> Publicado em</strong>:{" "}
                               {new Date(project.date).toLocaleDateString()}
                           </span>
                           <span>
                               <strong className="font-bold">Tipo</strong>:{" "}
                               {project.projectType}
                           </span>
                            <p className="my-3 text-lg text-black leading-relaxed">
                                {project.description}
                            </p>
                           <h2 className="text-lg font-bold text-blue-300 flex justify-start">Tecnologias:</h2>
                           <p className="text-md font-bold text-black leading-relaxed">{project.tags}</p>

                            <a href={project.link} alt={project.title} target="_blank" rel="noopener noreferrer" className="pt-2 text-blue-400 text-lg font-bold flex justify-center hover:opacity-75">
                                Ver o projeto
                            </a>
                       </div>
                   </article>
                   ))}
               </section>
           </section>
       </main>
   )
};